import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Books } from "./components/books/Books";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase-functions/config";
import { useEffect, useState } from "react";
import { Login } from "./components/auth/Login";
import { getDocument } from "./firebase-functions/database";
import { Upload } from "./components/books/Upload";
import { Wordle } from "./components/wordle/Wordle";

function App() {
  const [user, setUser] = useState();
  const [userInfo, setUserInfo] = useState();
  useEffect(() => {
    onAuthStateChanged(auth, (u) => {
      if (auth.currentUser) {
        setUser(auth.currentUser);
        getDocument("users", auth.currentUser.uid).then((res) => {
          if (res.success) {
            setUserInfo(res?.data || {});
          }
        });
      }
    });
  }, [auth]);

  return (
    <BrowserRouter>
      <Routes>
        {userInfo ? (
          <Route>
            <Route path="/" element={<Books userInfo={userInfo} />} />{" "}
            <Route path="/books" element={<Books userInfo={userInfo} />} />{" "}
            <Route
              path="/books/:bookId"
              element={<Books userInfo={userInfo} />}
            />{" "}
            {userInfo && userInfo?.role === "admin" && (
              <Route path="/upload" element={<Upload />} />
            )}
          </Route>
        ) : (
          <Route>
            <Route path="*" element={<Login />} />
          </Route>
        )}
        <Route path="/wordle" element={<Wordle />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
