import { useEffect, useState } from "react";
import bookList from "../../assets/books.json";
import { auth } from "../../firebase-functions/config";
import { createDocument } from "../../firebase-functions/database";
export const Upload = () => {
  const [len, setLen] = useState();
  useEffect(() => {
    setLen(bookList.length);
  }, []);
  const uploadBooks = () => {
    bookList.map((book) => {
      createDocument("books", book.bookId + "", book, auth.currentUser).then(
        (res) => {
          if (res.success) {
            setLen(len - 1);
          } else {
            alert(book.title, " failed to load", res.message);
          }
        }
      );
    });
  };
  return (
    <div>
      <h1>Upload Books {len}</h1>
      <button
        onClick={() => {
          uploadBooks();
        }}
      >
        Start Uploading
      </button>
    </div>
  );
};
