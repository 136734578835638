const { GoogleGenerativeAI } = require("@google/generative-ai");

// Access your API key as an environment variable (see "Set up your API key" above)
const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_KEY);

// ...

// For text-only input, use the gemini-pro model
export const model = genAI.getGenerativeModel({ model: "gemini-pro" });

// ...
