import { useLocation } from "react-router-dom";
import { BooksNavbar } from "./BooksNavber";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import "../../style/Books.css";
import { model } from "../../gemini/init";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import { getDocument, updateDocument } from "../../firebase-functions/database";
import OpenAI from "openai";
import { auth } from "../../firebase-functions/config";
import { ToastContainer, toast } from "react-toastify";

export const Books = (props) => {
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });
  const location = useLocation();
  const [context, setContext] = useState("");
  const [bookId, setBookId] = useState();
  const [book, setBook] = useState();
  const [bookLength, setBookLength] = useState(0);
  const [page, setPage] = useState();
  const [bookPages, setBookPages] = useState([]);
  const [sections, setSections] = useState([]);
  const [step, setStep] = useState(1);
  const [doneLoading, setDoneLoading] = useState(false);

  const fetchPages = async (id) => {
    if (id) {
      setBookPages([]);
      setPage(0);

      for (let page = 3; page <= 40; page++) {
        const url =
          "https://mi.content.readinga-z.com/raz_book_image/" +
          id +
          "/projectable/large/1/book/page-" +
          page +
          ".jpg";

        try {
          const response = await fetch(url);

          if (response.ok) {
            setBookPages((previousPages) => [...previousPages, response.url]);
          }
        } catch (error) {
          toast.info("Done Loading the books", {
            position: "top-center",
          });
          setDoneLoading(true);
          break;
        }
      }
    }
  };

  useEffect(() => {
    setBookId(
      location.pathname.split("/")[location.pathname.split("/").length - 1]
    );
  }, [location]);

  useEffect(() => {
    if (bookId) {
      let tempBook;
      getDocument("books", bookId).then((res) => {
        if (res.success) {
          setBook(res.data);
          tempBook = res.data;

          setContext(tempBook?.content);

          const data = tempBook?.bookTypeInfo.split(" ");
          const length = data[data?.length - 2];
          setBookLength(length || 0);
          setPage(0);
        }
      });
    }
    fetchPages(bookId);
  }, [bookId]);

  const cleanUp = () => {
    async function run() {
      const data = book?.bookTypeInfo.split(" ");

      const promptmain =
        "This is the first part of the text that I extracted from images. try to detect and fix the spelling mistakes. Do not touch the sentence structure. All so remove the page numbers, footers, possible captions and unnecessary line breaks. Make sure the formatting is in pharagraphs. Do not rephrase:\n";

      const prompt = promptmain + book?.content;
      console.log(prompt);

      const completion = await openai.chat.completions.create({
        messages: [{ role: "system", content: prompt }],
        model: "gpt-3.5-turbo",
      });

      const text = completion.choices[0].message.content;

      navigator.clipboard.writeText(prompt);

      if (text) {
        setContext(text + "\n");
        toast.info("done cleaning up", {
          position: "top-center",
          autoClose: 1000,
        });
      } else {
        toast.error("Something went wrong", {
          position: "top-center",
          autoClose: 1000,
        });
      }
    }

    if (context) {
      try {
        run();
      } catch (e) {
        toast.error("Couldn't clean up the book!", {
          position: "top-center",
          autoClose: 1000,
        });
      }
    }
  };

  const cleanUp2 = async () => {
    const promptmain =
      "This is the second part of the text that I extracted from images. try to detect and fix the spelling mistakes. Do not touch the sentence structure. All so remove the page numbers, footers, possible captions and unnecessary line breaks. It also has a book summary part. remove that as well. Do not rephrase. Make sure the formatting is in pharagraphs:\n";

    const prompt =
      promptmain +
      book.content
        .split("\n\n")
        .slice(
          Math.floor(book?.content.split("\n\n").length / 4),
          Math.floor(book?.content.split("\n\n").length / 2),
          book?.content.split("\n\n").length + 1
        )
        .join("\n\n");
    console.log(prompt);
    navigator.clipboard.writeText(prompt);
    const result = await model.generateContent(prompt);
    const response = await result.response;
    if (response) {
      const text = response.text();

      toast.info("done cleaning up", {
        position: "top-center",
        autoClose: 1000,
      });
      let temp = "";
      temp = temp + context;
      temp = temp + "\n" + text;
      setContext(temp);
    } else {
      toast.error("Something went wrong", {
        position: "top-center",
        autoClose: 1000,
      });
    }
  };

  const cleanUp3 = async () => {
    const promptmain =
      "This is part 3 of the text that I extracted from images. try to detect and fix the spelling mistakes. Do not touch the sentence structure. All so remove the page numbers, footers, possible captions and unnecessary line breaks. It also has a book summary part. remove that as well. Do not rephrase. Make sure the formatting is in pharagraphs:\n";

    const prompt =
      promptmain +
      book.content
        .split("\n\n")
        .slice(
          Math.floor(book?.content.split("\n\n").length / 2),
          Math.floor((book?.content.split("\n\n").length * 3) / 4),
          book?.content.split("\n\n").length + 1
        )
        .join("\n\n");
    console.log(prompt);
    navigator.clipboard.writeText(prompt);
    const result = await model.generateContent(prompt);
    const response = await result.response;
    if (response) {
      const text = response.text();

      toast.info("done cleaning up", {
        position: "top-center",
        autoClose: 1000,
      });
      let temp = "";
      temp = temp + context;
      temp = temp + "\n" + text;
      setContext(temp);
    } else {
      toast.error("Something went wrong", {
        position: "top-center",
        autoClose: 1000,
      });
    }
  };

  const cleanUp4 = async () => {
    const promptmain =
      "This is part 4 of the text that I extracted from images. try to detect and fix the spelling mistakes. Do not touch the sentence structure. All so remove the page numbers, footers, possible captions and unnecessary line breaks. It also has a book summary part. remove that as well. Do not rephrase. Make sure the formatting is in pharagraphs. DO NOT rephrase :\n";

    const prompt =
      promptmain +
      book.content
        .split("\n\n")
        .slice(
          Math.floor((book?.content.split("\n\n").length * 3) / 4),
          book?.content.split("\n\n").length + 1
        )
        .join("\n\n");
    console.log(prompt);
    navigator.clipboard.writeText(prompt);
    const result = await model.generateContent(prompt);
    const response = await result.response;
    if (response) {
      const text = response.text();

      toast.info("done cleaning up", {
        position: "top-center",
        autoClose: 1000,
      });
      let temp = "";
      temp = temp + context;
      temp = temp + "\n" + text;
      setContext(temp);
    } else {
      toast.error("Something went wrong", {
        position: "top-center",
        autoClose: 1000,
      });
    }
  };

  const MarkAsError = () => {
    updateDocument(
      "books",
      bookId,
      {
        markedBy: auth.currentUser.uid,
        updatedAt: new Date(),
        status: "error",
      },
      auth.currentUser
    ).then((res) => {
      if (res.success) {
        toast.success("Reported successfully", {
          position: "top-center",
          autoClose: 1000,
        });
      } else {
        toast.error("something went wrong", {
          position: "top-center",
          autoClose: 1000,
        });
      }
    });

    getDocument("stats", "errors").then((res) => {
      let errors = [];

      if (res.success) {
        if (res.data[book.level]) {
          errors = res.data[book.level];
        }
        if (!errors.includes(bookId)) {
          errors.push(parseInt(bookId));
        }
        updateDocument(
          "stats",
          "errors",
          {
            [book.level]: errors,
          },
          auth.currentUser
        );
      } else {
        res.data[book.level] = [];
        res.data[book.level].push(parseInt(bookId));
      }
    });
  };
  const download = () => {
    if (context !== "") {
      updateDocument(
        "books",
        bookId,
        {
          content: context,
          cleanedBy: auth.currentUser.uid,
          updatedAt: new Date(),
          status: "clean",
        },
        auth.currentUser
      ).then((res) => {
        if (res.success) {
          toast.success("book updated succesfully", {
            position: "top-center",
            autoClose: 1000,
          });
        } else {
          toast.error("something went wrong", {
            position: "top-center",
            autoClose: 1000,
          });
        }
      });

      getDocument("stats", "cleanBooks").then((res) => {
        let cleanBooks = [];

        if (res.success) {
          if (res.data[book.level]) {
            cleanBooks = res.data[book.level];
          }
        }
        if (!cleanBooks.includes(bookId)) {
          cleanBooks.push(parseInt(bookId));
        }
        updateDocument(
          "stats",
          "cleanBooks",
          {
            [book.level]: cleanBooks,
          },
          auth.currentUser
        );
      });
    }
  };

  return (
    <div className="container">
      <ToastContainer />
      <BooksNavbar userInfo={props.userInfo} />
      {bookId && (
        <div className="book-container">
          <div className="div-title">
            <h2> {book?.title} </h2>
            <h4>
              {context.split(" ").length +
                context.trim().split("\n").length -
                context.trim().split("\n\n").length -
                1}
              /{bookLength}
            </h4>
            <div className="buttons">
              {step == 1 && (
                <button
                  onClick={() => {
                    setStep(2);
                    cleanUp();
                  }}
                >
                  ✨ AI
                </button>
              )}

              <button className="btn-clean" onClick={() => download()}>
                Clean
              </button>
              {page === bookPages.length - 1 && (
                <button onClick={() => setPage(0)}>First Page</button>
              )}
              {page === 0 && doneLoading && (
                <button onClick={() => setPage(bookPages.length - 1)}>
                  Last Page
                </button>
              )}
              <button className="btn-error" onClick={() => MarkAsError()}>
                Error
              </button>
            </div>
          </div>
          <div className="div-body">
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {!doneLoading && <h4>Loading the pages ...</h4>}
              <textarea
                type="text"
                value={context}
                onChange={(e) => {
                  setContext(e.target.value);
                }}
                id=""
                style={{ height: "100%" }}
              />
            </div>
            <div className="div-img">
              <img src={bookPages[page]} alt="img" />
              <button
                className="btn-left"
                onClick={() => {
                  setPage(page - 1);
                }}
              >
                <FaAngleDoubleLeft />
              </button>
              <button
                className="btn-right"
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                <FaAngleDoubleRight />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
