import React, { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { logInWithEmailAndPassword } from "../../firebase-functions/auth";
import "../../style/Auth.css";
export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = logInWithEmailAndPassword;
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await logInWithEmailAndPassword(email, password).then((res) => {});
      navigate("/"); // Redirect to home page on successful login
    } catch (error) {
      console.error(error);
      // Handle errors (optional: display an error message)
    }
  };

  return (
    <div className="login-container">
      <h1>Login</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
};
