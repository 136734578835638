// firebase/database.js

import { firestore } from "./config"; // Import Firebase firestore and other configurations
import {
  doc,
  setDoc,
  updateDoc,
  getDoc,
  deleteDoc,
  limit,
} from "firebase/firestore";
import { getDocs, query, where, collection, orderBy } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

import { confirmPasswordReset } from "firebase/auth";

// Function to create a user document in Firestore

// Function to update user document in Firestore
export const updateUserDocument = async (uid, updatedData) => {
  try {
    await firestore.collection("students").doc(uid).update(updatedData);
    return { success: true, message: "User document updated successfully!" };
  } catch (error) {
    console.error("Error updating user document:", error);
    return { success: false, message: "Error updating user document." };
  }
};

export const getDocument = (col, docId) => {
  return new Promise((resolve, reject) => {
    getDoc(doc(firestore, col, docId))
      .then((snapshot) => {
        if (snapshot.exists()) {
          resolve({
            success: true,
            data: snapshot.data(),
          });
        } else {
          resolve({
            success: false,
            message: "Document does not exist",
          });
        }
      })
      .catch((error) => {
        reject({
          success: false,
          message: error,
        });
      });
  });
};

// Function to create a team document in Firestore
export const createDocument = (col, name, data) => {
  return new Promise((resolve, reject) => {
    setDoc(doc(firestore, col, name), data)
      .then((res) => {
        resolve({
          success: true,
          message: "document created successfully",
        });
      })
      .catch((error) => {
        reject({
          success: false,
          message: error,
        });
      });
  });
};

export const createDocumentWithRandomUid = (col, data) => {
  return new Promise((resolve, reject) => {
    const randomUid = uuidv4();

    setDoc(doc(firestore, col, randomUid), data)
      .then((res) => {
        resolve({
          success: true,
          message: "document created successfully",
          documentId: randomUid,
        });
      })
      .catch((error) => {
        reject({
          success: false,
          message: error,
        });
      });
  });
};

export const updateDocument = (col, docId, data, user) => {
  return new Promise((resolve, reject) => {
    // Assuming user.uid is the user's unique identifier, you can customize this based on your authentication setup

    if (!user || !user.uid) {
      reject({
        success: false,
        message: "User information missing or invalid",
      });
      return;
    }

    // Pass user information to Firestore security rules for validation
    const securityContext = { uid: user.uid };

    updateDoc(doc(firestore, col, docId), data, { context: securityContext })
      .then((res) => {
        resolve({
          success: true,
          message: res,
        });
      })
      .catch((error) => {
        reject({
          success: false,
          message: error,
        });
      });
  });
};

export const deleteDocument = (col, docId, user) => {
  return new Promise((resolve, reject) => {
    // Assuming user.uid is the user's unique identifier, you can customize this based on your authentication setup

    if (!user || !user.uid) {
      reject({
        success: false,
        message: "User information missing or invalid",
      });
      return;
    }

    // Pass user information to Firestore security rules for validation
    const securityContext = { uid: user.uid };

    deleteDoc(doc(firestore, col, docId), { context: securityContext })
      .then(() => {
        resolve({
          success: true,
          message: "Document deleted successfully",
        });
      })
      .catch((error) => {
        reject({
          success: false,
          message: error,
        });
      });
  });
};
