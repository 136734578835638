import { useState } from "react";
import "../../style/Wordle.css";
export const Wordle = () => {
  const answer = "ptople";
  const word = "battle";
  const [answer1, setAnswer1] = useState("");
  const [answer2, setAnswer2] = useState("");
  const [answer3, setAnswer3] = useState("");
  const [answer4, setAnswer4] = useState("");
  const [answer5, setAnswer5] = useState("");
  const [answer6, setAnswer6] = useState("");

  const grids = [answer1, answer2, answer3, answer4, answer5, answer6];
  const handleInput = (value) => {
    setAnswer1(value);
  };
  return (
    <div className="wordle-container">
      <h1>Wordle</h1>
      <input
        type="text"
        value={answer1}
        onChange={(e) => {
          handleInput(e.target.value);
        }}
        maxLength={answer.length}
      />
      <div className="box-container">
        {grids.map((inp, ind) => (
          <div className="blocks" key={ind}>
            {word.split("").map((letter, index) => {
              return (
                <p
                  key={index}
                  className={`letter ${
                    inp.charAt(index) === answer.split("")[index]
                      ? "letter-green"
                      : answer.split("").includes(inp.charAt(index))
                      ? "letter-yellow"
                      : inp.charAt(index)
                      ? "letter-gray"
                      : ""
                  }`}
                >
                  {inp.charAt(index)}
                </p>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};
