import { Link } from "react-router-dom";
import bookList from "../../assets/books.json";
import { useEffect, useState } from "react";
import { getDocument } from "../../firebase-functions/database";
export const BooksNavbar = (props) => {
  const [filteredBooks, setFilteredBooks] = useState([]);
  const [levels, setLevels] = useState([]);
  const [level, setLevel] = useState("");
  const [cleanBooks, setCleanBooks] = useState([]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (window.localStorage.getItem("level")) {
      setLevel(window.localStorage.getItem("level"));
    } else {
      setLevel(levels[0]);
    }
    const tempArray = bookList.filter((bk) => {
      setLevels(props?.userInfo?.levels);

      if (props?.userInfo?.levels?.length > 0) {
      }
      if (props?.userInfo?.role === "editor") {
        return (
          props?.userInfo?.levels && props?.userInfo?.levels.includes(bk.level)
        );
      } else if (props?.userInfo?.role === "admin") {
        setLevels([
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
          "Z1",
          "Z2",
        ]);

        return true;
      }
    });
  }, []);

  useEffect(() => {
    getDocument("stats", "cleanBooks").then((res) => {
      if (res.success) {
        setCleanBooks(res.data[level] || []);
      }
    });

    getDocument("stats", "errors").then((res) => {
      if (res.success) {
        setErrors(res.data[level] || []);
      }
    });
  }, [level]);

  useEffect(() => {
    const tempArray = bookList.filter(
      (bk) =>
        bk.level === level &&
        !cleanBooks.includes(bk.bookId) &&
        !errors.includes(bk.bookId)
    );

    setFilteredBooks(tempArray);
  }, [cleanBooks, errors]);
  return (
    <div className="book-navbar-container">
      <div className="header">
        <h4>
          {filteredBooks.length % 15 === 0 ? 15 : filteredBooks.length % 15}/
          {Math.ceil(filteredBooks?.length / 15)}
        </h4>
        {levels && (
          <select
            name="level"
            id="level"
            value={level}
            onChange={(e) => {
              setLevel(e.target.value);
              window.localStorage.setItem("level", e.target.value);
            }}
          >
            {levels.map((lv) => (
              <option value={lv} key={lv}>
                Level {lv}
              </option>
            ))}
          </select>
        )}
      </div>
      <ul>
        {filteredBooks
          .slice(
            0,
            filteredBooks.length % 15 === 0 ? 15 : filteredBooks.length % 15
          )
          .map((bk, i) => {
            return (
              <li key={bk.bookId + "-" + i}>
                <Link to={"/books/" + bk.bookId}> "{bk.title}",</Link>
              </li>
            );
          })}
      </ul>
    </div>
  );
};
